import { createApp } from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'

import { createPinia } from 'pinia'

// Library Components
import VueSweetalert2 from 'vue-sweetalert2'
import VueApexCharts from 'vue3-apexcharts'
import BootstrapVue3 from 'bootstrap-vue-3'
import CounterUp from 'vue3-autocounter'
import 'aos/dist/aos.css'

//library PrimeVue
import PrimeVue from 'primevue/config'
import Accordion from 'primevue/accordion'
import AccordionTab from 'primevue/accordiontab'
import Avatar from 'primevue/avatar'
import Badge from 'primevue/badge'
import BadgeDirective from 'primevue/badgedirective'
import BlockUI from 'primevue/blockui'
import Button from 'primevue/button'
import Calendar from 'primevue/calendar'
import Column from 'primevue/column'
import DataTable from 'primevue/datatable'
import Dialog from 'primevue/dialog'

import DialogService from 'primevue/dialogservice'
import DynamicDialog from 'primevue/dynamicdialog'

import Divider from 'primevue/divider'
import Dropdown from 'primevue/dropdown'
import Fieldset from 'primevue/fieldset'
import IconField from 'primevue/iconfield'
import InputIcon from 'primevue/inputicon'
import InputNumber from 'primevue/inputnumber'
import InputSwitch from 'primevue/inputswitch'
import InputText from 'primevue/inputtext'
import MultiSelect from 'primevue/multiselect'
import Panel from 'primevue/panel'
import Password from 'primevue/password'
import ProgressSpinner from 'primevue/progressspinner'
import ScrollTop from 'primevue/scrolltop'
import TabPanel from 'primevue/tabpanel'
import TabView from 'primevue/tabview'
import Tag from 'primevue/tag'
import Textarea from 'primevue/textarea'
import Toolbar from 'primevue/toolbar'
import Tree from 'primevue/tree'
import TriStateCheckbox from 'primevue/tristatecheckbox'

import 'primevue/resources/themes/bootstrap4-light-blue/theme.css'
import 'primeicons/primeicons.css'

// Custom Components & Directives
import globalComponent from './plugins/global-components'
import globalDirective from './plugins/global-directive'
import globalMixin from './plugins/global-mixin'

require('waypoints/lib/noframework.waypoints.min')
//CREACIÓN DE LA APP
const app = createApp(App)

//uso de vue-x & vue-router
app.use(store).use(router)

//PRIMEVUE and components
app.use(PrimeVue)
app.use(DialogService)

app.component('TreeView', Tree)
app.component('DataTablePrime', DataTable)
app.component('ColumnTable', Column)
app.component('MultiSelect', MultiSelect)
app.component('DropDown', Dropdown)
app.component('TextArea', Textarea)
app.component('PassWord', Password)
app.component('TabView', TabView)
app.component('TabPanel', TabPanel)
app.component('ButtonControl', Button)
app.component('DividerControl', Divider)
app.component('InputNumber', InputNumber)
app.component('ToolbarControl', Toolbar)
app.component('TagControl', Tag)
app.component('InputText', InputText)
app.component('DynamicDialog', DynamicDialog)
app.component('DialogControl', Dialog)
app.component('InputSwitch', InputSwitch)
app.component('FieldSet', Fieldset)
app.component('AvatarControl', Avatar)
app.component('AccordionControl', Accordion)
app.component('AccordionTab', AccordionTab)
app.component('BadgeControl', Badge)
app.component('IconField', IconField)
app.component('InputIcon', InputIcon)
app.component('ProgressSpinner', ProgressSpinner)
app.component('TriStateCheckbox', TriStateCheckbox)
app.component('ScrollTop', ScrollTop)
app.component('CalendarControl', Calendar)
app.component('BlockUI', BlockUI)
app.component('PanelControl', Panel)

app.directive('BadgeDirective', BadgeDirective)

// Library Components
app.use(VueSweetalert2)
app.use(VueApexCharts)
app.use(BootstrapVue3)
app.component('counter-up', CounterUp)

// Custom Components & Directives
app.use(globalComponent)
app.use(globalDirective)
app.mixin(globalMixin)

// Library Pinia
const pinia = createPinia()
app.use(pinia)

app.mount('#app')

export default app
